import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import './agreement/index.less'
export default defineComponent({
    setup () {

        const router:any = useRouter();
        const currentRoute = router.currentRoute;
        const flai = ref(false);

        const handleView = () => {
            if (['/flai/agreement', '/flai/privacy'].includes(currentRoute.value.path)) {
                flai.value = false;
            } else {
                flai.value = true;
            }
            
        }

        onMounted(() => {
            handleView()
        });
        

        return () => (
            <div>
                {
                    flai.value ? (
                        <div v-show="flai.value">
                            <img class="flaicontactus-img" src="https://img.fanwoon.com/flaicontactus.png" />
                        </div>
                    ) : null
                }
                <router-view/>
            </div>
        )
    }
})